@import '../../client/css/variables';
@import 'elements/mixins';
@import '../../client/css/maps';
@import '../../resources/css/mixins/respond-to';

// definitions

H1.pxml-stylesElement-H1 {
	-ms-name:"Heading 1";
	-ms-element:"true";
}

H2.pxml-stylesElement-H2 {
	-ms-name:"Heading 2";
	-ms-element:"true";
}

H3.pxml-stylesElement-H3 {
	-ms-name:"Heading 3";
	-ms-element:"true";
}

H4.pxml-stylesElement-H4 {
	-ms-name:"Heading 4";
	-ms-element:"true";
}

H5.ms-rteElement-H5 {
	-ms-name:"Heading 5";
	-ms-element:"true";
}

H6.ms-rteElement-H6 {
	-ms-name:"Heading 6";
	-ms-element:"true";
}

P.pxml-stylesElement-P {
	-ms-name:"Paragraph";
	-ms-element:"true";
}

SPAN.ms-rteElement-Button {
	-ms-name:"Button";
	-ms-element:"true";
}

// styling


.pxml-stylesElement-H1, .ms-rtestate-field h1, h1.ms-rteElement-H1 {
	@include styleguide("h1", false, $style-guide-heading-color);
}

.pxml-stylesElement-H2, .ms-rtestate-field h2, h2.ms-rteElement-H2 {
	@include styleguide("h2", false, $style-guide-heading-color);
}

.pxml-stylesElement-H3, .ms-rtestate-field h3, h3.ms-rteElement-H3 {
	@include styleguide("h3", false, $style-guide-heading-color);
}

.pxml-stylesElement-H4, .ms-rtestate-field h4, h4.ms-rteElement-H4 {
	@include styleguide("h4", false, $style-guide-heading-color);
}

.pxml-stylesElement-H5, .ms-rtestate-field h5, h5.ms-rteElement-H5 {
	@include styleguide("h5", false, $style-guide-heading-color);
}

.pxml-stylesElement-H6, .ms-rtestate-field h6, h6.ms-rteElement-H6 {
	@include styleguide("h6", false, $style-guide-heading-color);
}

h1.ms-rteElement-H1B {
	@include styleguide("h1", false, $style-guide-heading-color-alt);
}

h2.ms-rteElement-H2B {
	@include styleguide("h2", false, $style-guide-heading-color-alt);
}

h3.ms-rteElement-H3B {
	@include styleguide("h3", false, $style-guide-heading-color-alt);
}

h4.ms-rteElement-H4B {
	@include styleguide("h4", false, $style-guide-heading-color-alt);
}

.pxml-stylesElement-P, .ms-rtestate-field P, P.ms-rteElement-P {
	@include styleguide("p", false, $style-guide-color);
}

.ms-rteStyle-Normal {
	@include styleguide("p", false, $style-guide-color);
	background-color: transparent;
}

.ms-rteStyle-Quote {
	@include styleguide("quote", false, $style-guide-quote);
}

.ms-rteStyle-IntenseQuote {
	@include styleguide("quote", false, $style-guide-quote);
	text-decoration: underline;
}

.ms-rteStyle-Emphasis {
	@include styleguide("emphasis", false, $style-guide-emphasis);
}

.ms-rteStyle-IntenseEmphasis {
	@include styleguide("emphasis", false, $style-guide-emphasis);
	text-decoration: underline;
}

.ms-rteStyle-References {
	@include styleguide("reference", false, $style-guide-references);
}

.ms-rteStyle-IntenseReferences {
	@include styleguide("reference", false, $style-guide-references);
	text-decoration: underline;
}

.ms-rteStyle-Accent1 {
	@include styleguide("p", false, $style-guide-accent-1);
}

.ms-rteStyle-Accent2 {
	@include styleguide("p", false, $style-guide-accent-2);
}

SPAN.ms-rteElement-Button {
	background-color: $style-guide-button-bg;
	@include styleguide("link", false, $style-guide-button);
	padding: 5px 10px;
	-moz-border-radius: 3px;
  	-webkit-border-radius: 3px;
  	border-radius: 3px;
  	cursor: pointer;
  	border: solid 1px $style-guide-button-border;
}

